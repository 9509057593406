import BpmnRenderer from "bpmn-js/lib/draw/BpmnRenderer"
import { is } from "bpmn-js/lib/util/ModelUtil"

export default class CustomRenderer extends BpmnRenderer {
  constructor(
    config,
    eventBus,
    styles,
    pathMap,
    canvas,
    textRenderer,
    priority = 1500
  ) {
    super(config, eventBus, styles, pathMap, canvas, textRenderer, priority)

    this.originalDrawShape = super.drawShape
  }

  drawShape(parentNode, element) {
    if (this.canRender(element)) {
      const taskElement = {
        ...element,
        type: "bpmn:Task",
        businessObject: {
          ...element.businessObject,
          name: this.getElementTypeName(element),
        },
      }
      return this.originalDrawShape(parentNode, taskElement)
    }

    return this.originalDrawShape(parentNode, element)
  }

  getElementTypeName(element) {
    if (is(element, "mycustom:offer")) {
      const offerType = element.businessObject["type"]
      return offerType ? `${offerType} Offer` : "Offer"
    }
    if (is(element, "mycustom:quiz"))
      return element.businessObject.name || "Quiz"
    // if (is(element, "mycustom:checkout"))
    //   return element.businessObject.name || "Checkout"
      if (is(element, "mycustom:landing"))
        return element.businessObject.name || "Landing Page"
    if (is(element, "mycustom:upsell"))
      return element.businessObject.name || "Upsell"
    if (is(element, "mycustom:downsell"))
      return element.businessObject.name || "Downsell"
    if (is(element, "mycustom:popup")) {
      const type = element.businessObject["type"]
        ? `( ${element.businessObject["type"]} )`
        : ""
      return `Popup \n ${type}`
    }
    return ""
  }

  canRender(element) {
    return (
      is(element, "bpmn:BaseElement") &&
      (is(element, "mycustom:offer") ||
        is(element, "mycustom:cross_sell") ||
        is(element, "mycustom:quiz") ||
        // is(element, "mycustom:checkout") ||
        is(element, "mycustom:popup") ||
        is(element, "mycustom:metrics")) ||
        is(element, "mycustom:upsell") ||
        is(element, "mycustom:downsell") ||
        is(element, "mycustom:landing")
    )
  }
}

CustomRenderer.$inject = [
  "config",
  "eventBus",
  "styles",
  "pathMap",
  "canvas",
  "textRenderer",
]
