import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Form,
  Modal,
  Spinner,
} from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import BasicInfo from "./Sections/basic-info"
import Pricing from "./Sections/pricing"
import Quantity from "./Sections/quantity"
import Shipping from "./Sections/shipping"
import HcExtraFields from "./Sections/hc-extra-fields"
import Subscriptions from "./Sections/subscriptions"
import OfferFallback from "./Sections/offer-fallback"
import SearchProducts from "../../../components/Common/SearchProducts"
import {
  showErrorToastr,
  showSuccessToastr,
} from "../../../components/Common/toastr"
import { callGetApi, callPostApi } from "../../../utils/api"
import * as Yup from "yup"
import { useParams, useLocation, useNavigate } from "react-router-dom"
import { useFormik } from "formik"
import "./add-offer.scss"

const AddOffer = () => {
  document.title = "Add Offer"
  const history = useNavigate()
  const { funnelId } = useParams()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const offerSetId = queryParams.get("offerSetId")
  const parentId = queryParams.get("parentId")
  const shop = queryParams.get("shop")
  const [isOpen, setIsOpen] = useState(false)
  const [isUpgradeModalOpen, setIsUpgradeModalOpen] = useState(false)
  const [
    isModalUpgradeAdditionalProductsOpen,
    setIsModalUpgradeAdditionalProductsOpen,
  ] = useState(false)
  const [isExtraOpen, setIsExtraOpen] = useState(false)
  const [isFallbackOpen, setIsFallbackOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isUpdateLoading, setIsUpdateLoading] = useState(false)
  const [pricingTypes, setPricingTypes] = useState([])
  const [shippingTypes, setShippingTypes] = useState([])
  const [offerProduct, setOfferProduct] = useState([])
  const [replacementProducts, setReplacementProducts] = useState([])
  const [upgradeProduct, setUpgradeProduct] = useState(null)
  const [extraProducts, setExtraProducts] = useState([])
  const [fallbackProducts, setFallbackProducts] = useState([])
  const [upgradeAdditionalProducts, setUpgradeAdditionalProducts] = useState([])
  const [isReplacementProducts, setIsReplacementProducts] = useState(false)
  const [isUpgradedProduct, setIsUpgradedProduct] = useState(false)
  const [isUpgradeAdditionalProducts, setIsUpgradeAdditionalProducts] =
    useState(false)
  const [perks, setPerks] = useState([])
  const [shaperPerks, setShaperPerks] = useState([])
  const validationType = useFormik({
    enableReinitialize: true,
    initialValues: {
      active: true,
      pricingTypeId: "1",
      pricingTypeValue: "0",
      displayQuantity: "Yes",
      limitQuantity: true,
      quantity: 1,
      shippingTypeId: "1",
      shippingValue: "0",
      bannerText: "",
      minStock: "",
      productFallbackUUID: "",
      approveButtonText: "",
      orderProcessingText: "",
      titleText: "",
      subtitleText: "",
      copyText: "",
      componentsConfig: "",
      componentsConfigAutoshipFallback: "",
      subscriptionType: "",
      subscriptionName: "",
      subscriptionName2: "",
      replacement_offers: "",
      extraProducts: "",
      fallbackProducts: "",
      upgrade_product_id: "",
      upgrade_additional_products: "",
      popupExperienceData: "",
      isPopupExperienceActive: true,
    },
    validationSchema: Yup.object().shape({
      pricingTypeValue: Yup.string().when("pricingTypeId", {
        is: val => val === "2",
        then: Yup.string().matches(
          /(^$)|(^100(\.0{1,2})?$)|(^([1-9]([0-9])?|0)\.(\.[0-9]{1,2})?$)|(^([1-9]([0-9])?|0)(\.[0-9]{1,2})?$)/,
          "Enter percentage between 0-100"
        ),
      }),
    }),
    onSubmit: values => {
      try {
        values.componentsConfig && JSON.parse(values.componentsConfig)
      } catch (error) {
        showErrorToastr(`Components Config JSON validation failed`)
        return
      }

      try {
        values.componentsConfigAutoshipFallback && JSON.parse(values.componentsConfigAutoshipFallback)
      } catch (error) {
        showErrorToastr(`Autoship Fallback Components Config JSON validation failed`)
        return
      }

      try {
        values.popupExperienceData && JSON.parse(values.popupExperienceData)
      } catch (error) {
        showErrorToastr(`Popup Experience Config JSON validation failed`)
        return
      }

      const idsForRepProducts = replacementProducts.map(item => item.id)
      const idsWithString = idsForRepProducts.join(", ")
      const idsForExtProducts = extraProducts.map(item => item.id)
      const idsForFallbackProducts = fallbackProducts.map(item => item.id)
      const idsWithExtString = idsForExtProducts.join(", ")
      const idsWithFallbackString = idsForFallbackProducts.join(", ")
      const idsForUpgradeAdditionalProducts = upgradeAdditionalProducts
        .map(item => item.id)
        .join(", ")
      let subscriptionName = ""
      let requiredPlanName = ""
      if (
        (values.subscriptionType === "standalone_club_shaperbox" &&
          values.subscriptionName2) ||
        (values.subscriptionType === "standalone_club_optional_shaperbox" &&
          values.subscriptionName2) ||
        (values.subscriptionType === "standalone_shaperbox_v1" &&
          values.subscriptionName2) ||
        (values.subscriptionType === "standalone_shaperbox_v2" &&
          values.subscriptionName2) ||
        (values.subscriptionType === "standalone_shaperbox_v3" &&
          values.subscriptionName2) ||
        (values.subscriptionType === "standalone_club_vip_shaperbox" &&
          values.subscriptionName2)
      ) {
        subscriptionName = `${values.subscriptionName},${values.subscriptionName2}`
      } else if (values.subscriptionType === "standalone_shaperbox") {
        subscriptionName = values.subscriptionName
        requiredPlanName = values.subscriptionName2
      } else {
        subscriptionName = values.subscriptionName
      }

      let payload = {
        funnelId,
        parentId: parentId || null,
        offerSetId,
        active: values.active,
        pricingTypeId: values.pricingTypeId,
        pricingTypeValue: values.pricingTypeValue
          ? Number(values.pricingTypeValue)
          : null,
        displayQuantity: values.displayQuantity === "Yes",
        limitQuantity: values.limitQuantity,
        quantity: values.quantity,
        shippingTypeId: values.shippingTypeId,
        shippingValue:
          shippingTypes?.filter(el => values.shippingTypeId === el.id)?.[0]
            ?.name === "free"
            ? null
            : values.shippingValue
            ? Number(values.shippingValue)
            : null,
        bannerText: values.bannerText,
        minStock: values.minStock,
        productFallbackUUID: values.productFallbackUUID,
        approveButtonText: values.approveButtonText,
        orderProcessingText: values.orderProcessingText,
        titleText: values.titleText,
        subtitleText: values.subtitleText,
        copyText: values.copyText,
        componentsConfig: values.componentsConfig,
        componentsConfigAutoshipFallback: values.componentsConfigAutoshipFallback,
        subscriptionType: values.subscriptionType,
        subscriptionName: subscriptionName,
        requiredPlanName,
        extraProducts: idsWithExtString,
        fallbackAutoship: idsWithFallbackString,
        replacement_offers: idsWithString,
        upgrade_product_id: upgradeProduct?.id,
        upgrade_additional_products: idsForUpgradeAdditionalProducts,
        pricingType: pricingTypes?.filter(
          el => values.pricingTypeId === el.id
        )?.[0],
        shippingType: shippingTypes?.filter(
          el => values.shippingTypeId === el.id
        )?.[0],
        image: offerProduct?.[0]?.images?.[0]?.src,
        name: offerProduct?.[0]?.title,
        productId: offerProduct?.[0]?.id,
        popupExperienceData: values.popupExperienceData,
        isPopupExperienceActive: values.isPopupExperienceActive,
      }
      if (offerProduct?.length > 0) {
        if (!values.subscriptionType || perks?.length > 0) {
          if (
            values.subscriptionType === "standalone_club_shaperbox" ||
            values.subscriptionType === "standalone_club_vip_shaperbox" ||
            values.subscriptionType === "standalone_club_optional_shaperbox" ||
            values.subscriptionType === "standalone_shaperbox_v1" ||
            values.subscriptionType === "standalone_shaperbox_v2" ||
            values.subscriptionType === "standalone_shaperbox_v3" ||
            values.subscriptionType === "standalone_shaperbox"
          ) {
            if (shaperPerks?.length === 0) {
              showErrorToastr(
                `Please get perks for the ${
                  values.subscriptionType === "standalone_shaperbox"
                    ? "Required Subscription"
                    : "Shaperbox Subscription"
                } first`
              )
            } else {
              setIsUpdateLoading(true)
              callPostApi(
                true,
                `/upselling/offers?shop=${shop}`,
                payload,
                onGetUpdateOfferSuccess,
                onGetUpdateOfferFail
              )
            }
          } else {
            setIsUpdateLoading(true)
            callPostApi(
              true,
              `/upselling/offers?shop=${shop}`,
              payload,
              onGetUpdateOfferSuccess,
              onGetUpdateOfferFail
            )
          }
        } else {
          showErrorToastr("Please get perks for the subscriptions first")
        }
      } else {
        showErrorToastr("Please Select the product")
      }
    },
  })
  const onGetUpdateOfferSuccess = () => {
    showSuccessToastr("Offer Created Successfully!")
    setIsUpdateLoading(false)
    history(`/create-funnel?funnelId=${funnelId}&shop=${shop}`)
  }

  const onGetUpdateOfferFail = () => {
    setIsUpdateLoading(false)
  }

  // load pricing types
  const onGetPricingTypesSuccess = data => {
    setIsLoading(false)
    setPricingTypes(data)
  }

  const onGetPricingTypesFail = () => {
    setIsLoading(false)
  }

  const loadPricingTypes = () => {
    callGetApi(
      true,
      `/upselling/offers/pricing-types?shop=${shop}`,
      onGetPricingTypesSuccess,
      onGetPricingTypesFail
    )
  }

  // load shipping types
  const onGetShippingTypesSuccess = data => {
    setShippingTypes(data)
    setIsLoading(false)
  }

  const onGetShippingTypesFail = () => {
    setIsLoading(false)
  }

  const loadShippingTypes = () => {
    callGetApi(
      true,
      `/upselling/offers/shipping-types?shop=${shop}`,
      onGetShippingTypesSuccess,
      onGetShippingTypesFail
    )
  }

  const returnSaveProducts = selectedProducts => {
    if (isReplacementProducts) {
      const duplicateProducts = [...selectedProducts, ...replacementProducts]
      const uniqueReplacementProducts = duplicateProducts.reduce((acc, obj) => {
        const existingProducts = acc.find(item => item.id === obj.id)
        if (!existingProducts) {
          acc.push(obj)
        }
        return acc
      }, [])
      setReplacementProducts(uniqueReplacementProducts)
    } else {
      setOfferProduct(selectedProducts)
    }
  }
  const returnSaveExtraProducts = selectedProducts => {
    const duplicateProducts = [...selectedProducts, ...extraProducts]
    const uniqueExtraProducts = duplicateProducts.reduce((acc, obj) => {
      const existingProducts = acc.find(item => item.id === obj.id)
      if (!existingProducts) {
        acc.push(obj)
      }
      return acc
    }, [])
    setExtraProducts(uniqueExtraProducts)
  }

  const returnSaveFallbackProducts = selectedProducts => {
    setFallbackProducts(selectedProducts)
  }

  const returnSaveUpgradeProduct = selectedProducts => {
    if (isUpgradedProduct) {
      setUpgradeProduct(selectedProducts[0])
    } else {
      setOfferProduct(selectedProducts)
    }
  }

  const returnSaveUpgradeAdditionalProducts = selectedProducts => {
    if (isUpgradeAdditionalProducts) {
      const duplicateProducts = [
        ...selectedProducts,
        ...upgradeAdditionalProducts,
      ]
      const uniqueUpgradeAdditionalReplacementProducts =
        duplicateProducts.reduce((acc, obj) => {
          const existingProducts = acc.find(item => item.id === obj.id)
          if (!existingProducts) {
            acc.push(obj)
          }
          return acc
        }, [])
      setUpgradeAdditionalProducts(uniqueUpgradeAdditionalReplacementProducts)
    } else {
      setOfferProduct(selectedProducts)
    }
  }

  useEffect(() => {
    setIsLoading(true)
    loadShippingTypes()
    loadPricingTypes()
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={"Funnels"} breadcrumbItem={"Add Offer"} />
          <div>
            <div
              className="back-button"
              onClick={() => {
                history(`/create-funnel?funnelId=${funnelId}&shop=${shop}`)
              }}
            >
              <i className="bx bx-arrow-back"></i>
            </div>
            <Form
              onSubmit={e => {
                e.preventDefault()
                validationType.handleSubmit()
                return false
              }}
            >
              {isLoading ? (
                <div className="spinner-loader">
                  <Spinner className="ms-2" color="primary" />
                </div>
              ) : (
                <Row>
                  <Col lg={12}>
                    <BasicInfo
                      validationType={validationType}
                      setIsOpen={setIsOpen}
                      offerProduct={offerProduct}
                      setIsReplacementProducts={setIsReplacementProducts}
                      setIsExtraOpen={setIsExtraOpen}
                      setExtraProducts={setExtraProducts}
                      extraProducts={extraProducts}
                    />
                  </Col>
                  <Col lg={12}>
                    <Pricing
                      validationType={validationType}
                      pricingTypes={pricingTypes}
                    />
                  </Col>
                  <Col lg={12}>
                    <Quantity validationType={validationType} />
                  </Col>
                  <Col lg={12}>
                    <OfferFallback validationType={validationType} />
                  </Col>
                  <Col lg={12}>
                    <Shipping
                      validationType={validationType}
                      shippingTypes={shippingTypes}
                    />
                  </Col>
                  <Col lg={12}>
                    <Subscriptions
                      validationType={validationType}
                      setIsOpen={setIsOpen}
                      setIsUpgradeModalOpen={setIsUpgradeModalOpen}
                      setIsModalUpgradeAdditionalProductsOpen={
                        setIsModalUpgradeAdditionalProductsOpen
                      }
                      setReplacementProducts={setReplacementProducts}
                      replacementProducts={replacementProducts}
                      setIsReplacementProducts={setIsReplacementProducts}
                      upgradeProduct={upgradeProduct}
                      setUpgradeProduct={setUpgradeProduct}
                      setIsUpgradedProduct={setIsUpgradedProduct}
                      setIsUpgradeAdditionalProducts={
                        setIsUpgradeAdditionalProducts
                      }
                      setUpgradeAdditionalProducts={
                        setUpgradeAdditionalProducts
                      }
                      upgradeAdditionalProducts={upgradeAdditionalProducts}
                      perks={perks}
                      setPerks={setPerks}
                      shaperPerks={shaperPerks}
                      setShaperPerks={setShaperPerks}
                      shop={shop}
                      setIsFallbackOpen={setIsFallbackOpen}
                      setFallbackProducts={setFallbackProducts}
                      fallbackProducts={fallbackProducts}
                    />
                  </Col>
                  <Col lg={12}>
                    <HcExtraFields validationType={validationType} />
                  </Col>
                  <Col lg={12}>
                    <Card>
                      <CardBody>
                        <div>
                          <div className="d-flex flex-wrap gap-2 form-btn">
                            <Button
                              type="submit"
                              color="primary"
                              isLoading={true}
                              disabled={isUpdateLoading}
                            >
                              {isUpdateLoading ? (
                                <Spinner
                                  size={"sm"}
                                  className="mx-3 mb-0 mt-1"
                                />
                              ) : (
                                "Submit"
                              )}
                            </Button>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              )}
            </Form>
          </div>
        </Container>
        <Modal
          isOpen={isOpen}
          toggle={() => {
            setIsOpen(!isOpen)
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myModalLabel">
              Add product
            </h5>
            <button
              type="button"
              onClick={() => {
                setIsOpen(false)
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <SearchProducts
              setIsOpen={setIsOpen}
              returnSaveProducts={returnSaveProducts}
              multiProducts={isReplacementProducts}
            />
          </div>
        </Modal>

        <Modal
          isOpen={isUpgradeModalOpen}
          toggle={() => {
            setIsUpgradeModalOpen(!isUpgradeModalOpen)
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="upgradeModalLabel">
              Add product to upgrade
            </h5>
            <button
              type="button"
              onClick={() => {
                setIsUpgradeModalOpen(false)
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <SearchProducts
              setIsOpen={setIsUpgradeModalOpen}
              returnSaveProducts={returnSaveUpgradeProduct}
              multiProducts={false}
            />
          </div>
        </Modal>

        <Modal
          isOpen={isModalUpgradeAdditionalProductsOpen}
          toggle={() => {
            setIsModalUpgradeAdditionalProductsOpen(
              !isModalUpgradeAdditionalProductsOpen
            )
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myModalLabel">
              Add product
            </h5>
            <button
              type="button"
              onClick={() => {
                setIsModalUpgradeAdditionalProductsOpen(false)
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <SearchProducts
              setIsOpen={setIsModalUpgradeAdditionalProductsOpen}
              returnSaveProducts={returnSaveUpgradeAdditionalProducts}
              multiProducts={isUpgradeAdditionalProducts}
            />
          </div>
        </Modal>

        <Modal
          isOpen={isExtraOpen}
          toggle={() => {
            setIsExtraOpen(!isExtraOpen)
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myModalLabel">
              Add product
            </h5>
            <button
              type="button"
              onClick={() => {
                setIsExtraOpen(false)
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <SearchProducts
              setIsOpen={setIsExtraOpen}
              returnSaveProducts={returnSaveExtraProducts}
              multiProducts={true}
            />
          </div>
        </Modal>
        <Modal
          isOpen={isFallbackOpen}
          toggle={() => {
            setIsFallbackOpen(!isFallbackOpen)
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myModalLabel">
              Add product
            </h5>
            <button
              type="button"
              onClick={() => {
                setIsFallbackOpen(false)
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <SearchProducts
              setIsOpen={setIsFallbackOpen}
              returnSaveProducts={returnSaveFallbackProducts}
              multiProducts={false}
            />
          </div>
        </Modal>
      </div>
    </React.Fragment>
  )
}

export default AddOffer
