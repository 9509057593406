import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Modal, ModalBody, Button } from "reactstrap"
import { showErrorToastr } from "components/Common/toastr"

const taskTypes = [
  { label: "Offer", type: "offer" },
  { label: "Quiz", type: "quiz" },
  // { label: "Checkout", type: "checkout" },
  { label: "Upsell", type: "upsell" },
  { label: "Downsell", type: "downsell" },
  { label: "Popup", type: "popup" },
  { label: "Landing Page", type: "landing" },
]

const funnelProperties = [
  { id: "status", label: "Status" },
  { id: "country", label: "Country" },
  { id: "offer_type", label: "Type" },
  { id: "checkout", label: "Checkout ID" },
  { id: "title", label: "Title" },
  { id: "funnel_url", label: "URL" },
]

const nodeProperties = {
  offer: [
    { id: "name", label: "Offer" },
    { id: "type", label: "Type" },
    // { id: "price_first_payment", label: "Price (1st Payment)" },
    // { id: "subscription_type", label: "Subscription Type" },
    // { id: "price_subscription", label: "Price (Subscription)" },
    // { id: "price_meta", label: "Price (Meta)" },
    { id: "retail_price", label: "Retail Price" },
    { id: "final_price", label: "Final Price" },
    { id: "discount", label: "Discount" },
    { id: "shipping", label: "Shipping" },
  ],
  upsell: [
    { id: "productType", label: "Type", },
    { id: "retail_price", label: "Retail Price" },
    { id: "final_price", label: "Final Price" },
    { id: "discount", label: "Discount" },
    { id: "product", label: "Product" },
  ],
  // checkout: [{ id: "hc_funnel", label: "HC Funnel ID" }],
  landing: [{ id: "url", label: "URL" }],
  quiz: [{ id: "url", label: "URL" }],
  popup: [{ id: "type", label: "Type" }],
  downsell: [
    { id: "retail_price", label: "Retail Price" },
    { id: "final_price", label: "Final Price" },
    { id: "discount", label: "Discount" },
    { id: "product", label: "Product" },
  ],
}

const statusOptions = [
  { label: "Active", value: "true" },
  { label: "Inactive", value: "false" },
]

export const countryList = [
  { label: "United States", value: "US" },
  { label: "Canada", value: "CA" },
  { label: "United Kingdom", value: "UK" },
  { label: "Uruguay", value: "UY" },
]

const offerTypes = [
  { label: "Store", value: "store" },
  { label: "Acquisition Main", value: "acquisition_main" },
  { label: "RMKT", value: "rmkt" },
]

const FilterModal = ({ show, onCloseClick, onApplyFilters, currentAppliedFilters }) => {
  const [conditions, setConditions] = useState([])
  const [matchType, setMatchType] = useState("all") // "all" or "some"

  const addCondition = () => {
    const lastCondition = conditions[conditions.length - 1]
  
    // Prevent adding a new condition if the last one is incomplete
    if (lastCondition && (!lastCondition.property || !lastCondition.operator || lastCondition.value === "")) {
      showErrorToastr("Complete the current condition before adding a new one.")
      return
    }
  
    setConditions([
      ...conditions,
      { objectType: "funnel", property: "status", operator: "is", value: "", order: "" },
    ])
  }
  

  useEffect(() => {
    if (show) {
      setConditions(currentAppliedFilters || [])
    }
  }, [show, currentAppliedFilters])

  const handleClearAll = () => {
    const clearedConditions = []
    const defaultMatchType = "all"
  
    setConditions(clearedConditions)
    setMatchType(defaultMatchType)
  
    onApplyFilters({ matchType: defaultMatchType, conditions: clearedConditions })
    onCloseClick()
  }

  const updateCondition = (index, key, value) => {
    setConditions((prevConditions) =>
      prevConditions.map((condition, i) => {
        if (i === index) {
          if (key === "objectType" && condition[key] !== value) {
            return { ...condition, objectType: value, property: "", order: "" }
          }
          return { ...condition, [key]: value }
        }
        return condition
      })
    )
  }  

  const removeCondition = (index) => {
    setConditions(conditions.filter((_, i) => i !== index))
  }

  const handleApplyFilters = () => {
    // Filter out incomplete conditions
    const validConditions = conditions.filter(
      (condition) =>
        condition.property &&
        condition.operator &&
        (condition.value !== "" || ["exists", "does not exist"].includes(condition.operator))
    )
  
    if (validConditions.length === 0) {
      showErrorToastr("Please add at least one valid condition before applying filters.")
      return
    }
  
    onApplyFilters({ matchType, conditions: validConditions })
    onCloseClick()
  }
  

  const getPropertiesForType = (objectType) => {
    if (objectType === "funnel") {
      return funnelProperties
    }
    return nodeProperties[objectType] || []
  }

  return (
    <Modal isOpen={show} toggle={onCloseClick} centered>
      <div className="modal-content">
        <ModalBody>
          <button
            type="button"
            onClick={onCloseClick}
            className="btn-close position-absolute end-0 top-0 m-3"
            style={{zIndex: 1}}
          ></button>
          <div className="text-center mb-4">
            <p style={{fontSize: '16px'}}>Filter Funnels</p>
          </div>
          <div className="mb-3">
            <label className="form-label">Match Type</label>
            <select
              className="form-control"
              value={matchType}
              onChange={(e) => setMatchType(e.target.value)}
            >
              <option value="all">All conditions must be true</option>
              <option value="some">Some conditions must be true</option>
            </select>
          </div>

          <div style={{ maxHeight: "400px", overflowY: "auto" }}>
            {conditions.map((condition, index) => (
              <div key={index} className="mb-3 d-flex align-items-center gap-2">
                <select
                  className="form-control"
                  value={condition.objectType}
                  onChange={(e) => updateCondition(index, "objectType", e.target.value)}
                >
                  <option value="funnel">Funnel</option>
                  {taskTypes.map((task) => (
                    <option key={task.type} value={task.type}>
                      {task.label}
                    </option>
                  ))}
                </select>

                <select
                  className="form-control"
                  value={condition.property}
                  onChange={(e) => updateCondition(index, "property", e.target.value)}
                >
                  <option value="">Select Property</option>
                  {getPropertiesForType(condition.objectType).map((prop) => (
                    <option key={prop.id} value={prop.id}>
                      {prop.label}
                    </option>
                  ))}
                </select>

                <select
                  className="form-control"
                  value={condition.operator}
                  onChange={(e) => updateCondition(index, "operator", e.target.value)}
                  disabled={
                    (condition.objectType === "upsell" && condition.property === "productType") || 
                    (condition.objectType === "offer" && condition.property === "type")
                  }
                >
                  { (condition.objectType === "upsell" && condition.property === "productType") || 
                    (condition.objectType === "offer" && condition.property === "type") ? (
                    <option value="is">is</option>
                  ) : (
                    <>
                      <option value="is">is</option>
                      <option value="is not">is not</option>
                      <option value="contains">contains</option>
                      <option value="does not contain">does not contain</option>
                      <option value="starts with">starts with</option>
                      <option value="ends with">ends with</option>
                      <option value="exists">exists</option>
                      <option value="does not exist">does not exist</option>
                    </>
                  )}
                </select>

                {condition.property === "status" ? (
                  <select
                    className="form-control"
                    value={condition.value}
                    onChange={(e) => updateCondition(index, "value", e.target.value)}
                  >
                    <option value="">Select Status</option>
                    {statusOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                ) : condition.property === "country" ? (
                  <select
                    className="form-control"
                    value={condition.value}
                    onChange={(e) => updateCondition(index, "value", e.target.value)}
                  >
                    <option value="">Select Country</option>
                    {countryList.map((country) => (
                      <option key={country.value} value={country.value}>
                        {country.label}
                      </option>
                    ))}
                  </select>
                ) : condition.property === "offer_type" ? (
                  <select
                    className="form-control"
                    value={condition.value}
                    onChange={(e) => updateCondition(index, "value", e.target.value)}
                  >
                    <option value="">Select Type</option>
                    {offerTypes.map((type) => (
                      <option key={type.value} value={type.value}>
                        {type.label}
                      </option>
                    ))}
                  </select>
                ) : condition.objectType === "upsell" && condition.property === "productType" ? (
                  <>
                    <select
                      className="form-control"
                      value={condition.value}
                      onChange={(e) => updateCondition(index, "value", e.target.value)}
                    >
                      <option value="">Select Upsell Type</option>
                      <option value="Club">Club</option>
                      <option value="Club+">Club+</option>
                    </select>

                    <span>on</span>

                    <select
                      className="form-control"
                      value={condition.order || ""}
                      onChange={(e) => updateCondition(index, "order", e.target.value)}
                    >
                      <option value="">Select Upsell Order</option>
                      <option value="2">Second Upsell</option>
                      <option value="3">Third Upsell</option>
                    </select>
                  </>
                ) : condition.property === "type" && condition.objectType === "offer" ? (
                  <select
                    className="form-control"
                    value={condition.value}
                    onChange={(e) => updateCondition(index, "value", e.target.value)}
                  >
                    <option value="">Select Offer Type</option>
                    <option value="PDP Special">PDP Special</option>
                    <option value="Cart Special">Cart Special</option>
                    <option value="Store Level">Store Level</option>
                  </select>
                ) : (
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Value"
                    value={condition.value}
                    onChange={(e) => updateCondition(index, "value", e.target.value)}
                    disabled={["exists", "does not exist"].includes(condition.operator)}
                  />
                )}

                <button
                  type="button"
                  className="btn btn-danger btn-sm"
                  onClick={() => removeCondition(index)}
                >
                  <i className="mdi mdi-close"></i>
                </button>
              </div>
            ))}
          </div>


          <Button
            style={{ backgroundColor: "#2e579b" }}
            onClick={addCondition}
            className="mb-3 w-100"
          >
            Add Condition
          </Button>

          <div style={{display: 'flex'}}>
            <Button
              onClick={handleClearAll}
              className="w-100 btn btn-danger"
              style={{marginRight: 10}}
            >
              Clear All
            </Button>

            <Button
              style={{ backgroundColor: "#142541" }}
              onClick={handleApplyFilters}
              className="w-100"
            >
              Apply Filters
            </Button>
          </div>
        </ModalBody>
      </div>
    </Modal>
  )
}

FilterModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onCloseClick: PropTypes.func.isRequired,
  currentAppliedFilters: PropTypes.array,
}

FilterModal.defaultProps = {
  currentAppliedFilters: [],
}

export default FilterModal
