import {
  TextFieldEntry,
  isTextFieldEntryEdited,
  SelectEntry,
  isSelectEntryEdited,
} from "@bpmn-io/properties-panel"
import { is } from "bpmn-js/lib/util/ModelUtil"
import { html } from "htm/preact"
import { useService } from "bpmn-js-properties-panel"

function CustomPropertiesProvider(propertiesPanel, translate) {
  this.getGroups = function (element) {
    return function (groups) {
      const customGroup = createCustomGroup(element, translate)
      if (customGroup) {
        groups.push(customGroup)
      }
      return groups
    }
  }

  propertiesPanel.registerProvider(500, this)
}

CustomPropertiesProvider.$inject = ["propertiesPanel", "translate"]

function createCustomGroup(element, translate) {
  if (
    is(element, "mycustom:offer") ||
    is(element, "mycustom:upsell") ||
    is(element, "mycustom:downsell") ||
    is(element, "mycustom:landing") ||
    // is(element, "mycustom:checkout") ||
    is(element, "mycustom:popup")
  ) {
    const customGroup = {
      id: "customGroup",
      label: translate("Custom Properties"),
      entries: [],
    }

    const properties = getProperties(element)

    properties.forEach(property => {
      customGroup.entries.push({
        id: property.id,
        element,
        component: (props) => {
          if (property.type === "select") {
            return CustomSelectEntry({ ...props, property })
          }
          return CustomTextFieldEntry({ ...props, property })
        },
        isEdited: property.type === "select" ? isSelectEntryEdited : isTextFieldEntryEdited,
      })
    })

    return customGroup
  }
  return null
}

function getProperties(element) {
  if (is(element, "mycustom:offer")) {
    const type = element.businessObject["type"] || "Store Level"

    const commonFields = [
      { id: "retail_price", label: "Retail Price" },
      { id: "final_price", label: "Final Price" },
      { id: "discount", label: "Discount" },
    ]

    if (type === "Store Level") {
      return [
        { id: "type", label: "Offer Type", type: "select" },
        ...commonFields,
        { id: "shipping", label: "Shipping" },
      ]
    } else if (type === "PDP Special") {
      return [
        { id: "type", label: "Offer Type", type: "select" },
        { id: "name", label: "Offer" },
        ...commonFields,
      ]
    } else if (type === "Cart Special") {
      return [
        { id: "type", label: "Offer Type", type: "select" },
        { id: "name", label: "Offer" },
        ...commonFields,
      ]
    }
  } else if (is(element, "mycustom:upsell")) {
    return [
      { id: "productType", label: "Product Type", type: "select" },
      { id: "retail_price", label: "Retail Price*" },
      { id: "final_price", label: "Final Price*" },
      { id: "discount", label: "Discount" },
      { id: "product", label: "Product*" },
      { id: "preview", label: "Preview" }
    ]
  } else if (
    is(element, "mycustom:downsell")
  ) {
    return [
      { id: "retail_price", label: "Retail Price*" },
      { id: "final_price", label: "Final Price*" },
      { id: "discount", label: "Discount" },
      { id: "product", label: "Product*" },
      { id: "preview", label: "Preview" }
    ]
  // } else if (is(element, "mycustom:checkout")) {
  //   return [{ id: "hc_funnel", label: "HC Funnel ID*" }]
  } else if (is(element, "mycustom:landing")) {
    return [{ id: "url", label: "URL*" }]
  } else if (is(element, "mycustom:popup")) {
    return [{ id: "type", label: "Type*" }]
  }
  return []
}

function CustomSelectEntry({ element, property }) {
  const modeling = useService("modeling")
  const translate = useService("translate")
  const nodeType = element.type

  const getValue = () => element.businessObject[property.id] || "Product"

  const setValue = (value) => {
    modeling.updateProperties(element, { [property.id]: value })

    if (nodeType === "mycustom:upsell") {
      modeling.updateProperties(element, {
        product: value === "Product" ? undefined : value,
        retail_price: null,
        final_price: null,
        discount: null,
      })
    } else if (nodeType === "mycustom:offer") {
      modeling.updateProperties(element, {
        name: value === "Store Level" ? undefined : null,
        retail_price: null,
        final_price: null,
        discount: null,
        shipping: value === "Store Level" ? null : undefined,
      })
    }
  }

  const options =
    nodeType === "mycustom:upsell"
      ? [
          { value: "Product", label: "Product" },
          { value: "Club", label: "Club" },
          { value: "Club+", label: "Club+" },
        ]
      : [
          { value: "Store Level", label: "Store Level" },
          { value: "PDP Special", label: "PDP Special Offer" },
          { value: "Cart Special", label: "Cart Special Offer" },
        ]

  return html`
    <${SelectEntry}
      id=${property.id}
      element=${element}
      label=${translate(property.label)}
      getValue=${getValue}
      setValue=${setValue}
      getOptions=${() => options}
    />
  `
}

function CustomTextFieldEntry({ element, id, property }) {
  const modeling = useService("modeling")
  const translate = useService("translate")
  const debounce = useService("debounceInput")

  const { label } = property

  const getValue = () => {
    return element.businessObject[property.id] || ""
  }

  const setValue = value => {
    modeling.updateProperties(element, { [property.id]: value })
  }

  const isDisabled = (id) => {
    if (!is(element, "mycustom:upsell")) return false
    const productType = element.businessObject["productType"]
    return (productType === "Club" || productType === "Club+") && id != 'preview'
  }

  const highDebounce =
    fn =>
    (...args) =>
      setTimeout(() => fn(...args), 2000)

  return html`
    <${TextFieldEntry}
      id=${id}
      element=${element}
      label=${translate(label)}
      getValue=${getValue}
      setValue=${value => {}}
      disabled=${isDisabled(id)}
      debounce=${highDebounce}
      onBlur=${e => setValue(e.target.value)}
    />
  `
}

export default CustomPropertiesProvider
