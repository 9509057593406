import React, { useState } from "react"
import AceEditor from "react-ace"
import { Card, CardBody, CardTitle, Col, Label, Row } from "reactstrap"
import Ajv from "ajv"
import "ace-builds/webpack-resolver"

const AutoshipExtraFields = ({ validationType }) => {
  const [jsonError, setJsonError] = useState("") // State to store JSON validation error
  const ajv = new Ajv()

  const validateJson = jsonString => {
    try {
      const parsedJson = JSON.parse(jsonString)
      return ajv.validateSchema(parsedJson)
    } catch (error) {
      return false
    }
  }

  const handleJsonChange = value => {
    validationType.setFieldValue("popupExperienceData", value)
    if (!validateJson(value)) {
      setJsonError("Invalid JSON format or schema")
    } else {
      setJsonError("") // Clear error if JSON is valid
    }
  }
  return (
    <div>
      <div>
        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                <CardTitle>Autoship Extra Fields</CardTitle>
                <div className="mb-3">
                  <Label className="form-label">
                    Customize Popup Experience Config
                  </Label>
                  <AceEditor
                    mode="json"
                    theme="dawn"
                    name="popupExperienceDataEditor"
                    value={validationType.values.popupExperienceData || ""}
                    onChange={handleJsonChange}
                    onBlur={() =>
                      validationType.handleBlur({
                        target: { name: "popupExperienceData" },
                      })
                    }
                    width="100%"
                    height="200px"
                    fontSize={14}
                    showPrintMargin={false}
                    showGutter={true}
                    highlightActiveLine={true}
                    setOptions={{
                      enableBasicAutocompletion: true,
                      enableLiveAutocompletion: true,
                      enableSnippets: true,
                      showLineNumbers: true,
                      tabSize: 2,
                    }}
                    className={`form-control ${jsonError ? "is-invalid" : ""}`}
                  />
                  {jsonError && (
                    <div className="invalid-feedback">{jsonError}</div>
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default AutoshipExtraFields
