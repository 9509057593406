import React, { useEffect, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Label,
  Row,
  Input,
  Table,
} from "reactstrap"
import { callGetApi } from "../../../../utils/api"
import AutoshipExtraFields from "./autoship-extra-fields"

const Subscriptions = ({
  validationType,
  setIsOpen,
  setIsUpgradeModalOpen,
  setIsModalUpgradeAdditionalProductsOpen,
  replacementProducts,
  setIsReplacementProducts,
  setReplacementProducts,
  upgradeProduct,
  setUpgradeProduct,
  setIsUpgradedProduct,
  setIsUpgradeAdditionalProducts,
  upgradeAdditionalProducts,
  setUpgradeAdditionalProducts,
  perks,
  setPerks,
  editOffer,
  shaperPerks,
  setShaperPerks,
  shop,
  setIsFallbackOpen,
  setFallbackProducts,
  fallbackProducts,
}) => {
  const [allShippingTypes, SetAllShippingTypes] = useState([])
  const [selectedOption, setSelectedOption] = useState("")

  const onGetAllShippingTypesSuccess = data => {
    SetAllShippingTypes(data || [])
  }

  const onGetAllShippingTypesFail = () => {}

  const loadAllShippingTypes = () => {
    const storeMap = {
      "the-spa-dr": "the-spa-dr",
      "the-spa-dr.myshopify.com": "the-spa-dr",
      shapermint: "shapermint",
      "shapermint.myshopify.com": "shapermint",
      revel: "revel",
      "revel.myshopify.com": "revel",
      "truekind-store": "truekind-store",
      "truekind-store.myshopify.com": "truekind-store",
    }
    Object.freeze(storeMap)
    const shopName = storeMap[shop] || shop
    callGetApi(
      true,
      `/upselling/subscription_types?store=${shopName}`,
      onGetAllShippingTypesSuccess,
      onGetAllShippingTypesFail
    )
  }

  const handleGetPerks = (value, type) => {
    let availablePerks = allShippingTypes?.filter(el => el.name === value)?.[0]
      ?.discounts
    if (type === "subscriptionName") {
      setPerks(availablePerks)
    } else {
      setShaperPerks(availablePerks)
    }
  }

  useEffect(() => {
    if (allShippingTypes?.length > 0) {
      if (editOffer && validationType?.values?.subscriptionName) {
        handleGetPerks(
          validationType?.values?.subscriptionName,
          "subscriptionName"
        )
      }
      if (editOffer && validationType?.values?.subscriptionName2) {
        handleGetPerks(
          validationType?.values?.subscriptionName2,
          "subscriptionName2"
        )
      }
    }
  }, [editOffer, allShippingTypes])

  useEffect(() => {
    loadAllShippingTypes()
  }, [])

  useEffect(() => {
    if (replacementProducts?.length > 0) {
      setSelectedOption("replacement")
    } else if (upgradeProduct) {
      setSelectedOption("upgrade")
    }
  }, [replacementProducts, upgradeProduct])

  return (
    <div>
      <div>
        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                <CardTitle>Subscription</CardTitle>
                <div>
                  <div className="mb-3">
                    <Label className="form-label">Subscription Type</Label>
                    <select
                      name="subscriptionType"
                      className="form-select"
                      onChange={e => {
                        setPerks([])
                        setShaperPerks([])
                        validationType.setFieldValue("subscriptionName", "")
                        validationType.setFieldValue("subscriptionName2", "")
                        validationType.setFieldValue(
                          "subscriptionType",
                          e.target.value
                        )
                      }}
                      onBlur={validationType.handleBlur}
                      value={validationType.values.subscriptionType}
                    >
                      <option value="">No</option>
                      <option value="standalone_club">StandaloneClub</option>
                      <option value="standalone_club_v2">
                        StandaloneClubV2
                      </option>
                      <option value="standalone_club_v3">
                        StandaloneClubV3
                      </option>
                      <option value="standalone_club_v4">
                        StandaloneClubV4
                      </option>
                      <option value="standalone_truekind_elite">
                        StandaloneTruekindElite
                      </option>
                      <option value="standalone_shaperbox">
                        StandaloneShaperbox
                      </option>
                      <option value="standalone_club_vip_shaperbox">
                        ClubValuePropBox
                      </option>
                      <option value="standalone_shaperbox_plus_v1">
                        StandaloneShaperboxPlusV1
                      </option>
                      <option value="standalone_shaperbox_plus_v2">
                        StandaloneShaperboxPlusV2
                      </option>
                      <option value="standalone_shaperbox_v1">
                        StandaloneShaperboxV1
                      </option>
                      <option value="standalone_shaperbox_v2">
                        StandaloneShaperboxV2
                      </option>
                      <option value="standalone_shaperbox_v3">
                        StandaloneShaperboxV3
                      </option>
                      <option value="standalone_club_shaperbox">
                        StandaloneClubShaperbox
                      </option>
                      <option value="standalone_club_optional_shaperbox">
                        StandaloneClubOptionalShaperbox
                      </option>
                      <option value="embedded">Embedded</option>
                      <option value="autoship">Autoship</option>
                      <option value="autoship_v2">AutoshipV2</option>
                    </select>
                  </div>
                  {!!validationType.values.subscriptionType && (
                    <div className="mb-3">
                      <Label className="form-label">
                        {(validationType.values.subscriptionType ===
                          "standalone_club_shaperbox" ||
                          validationType.values.subscriptionType ===
                            "standalone_club_optional_shaperbox" ||
                          validationType.values.subscriptionType ===
                            "standalone_shaperbox_v1" ||
                          validationType.values.subscriptionType ===
                            "standalone_shaperbox_v2" ||
                          validationType.values.subscriptionType ===
                            "standalone_shaperbox_v3" ||
                          validationType.values.subscriptionType ===
                            "standalone_club_vip_shaperbox") &&
                          "Club "}
                        {(validationType.values.subscriptionType ===
                          "standalone_shaperbox_plus_v1" ||
                          validationType.values.subscriptionType ===
                            "standalone_shaperbox_plus_v2") &&
                          "Shaperbox "}
                        Subscription Name (Subscription Plan)
                      </Label>
                      <select
                        name="subscriptionName"
                        className="form-select"
                        onChange={e => {
                          handleGetPerks(e.target.value, "subscriptionName")
                          validationType.setFieldValue(
                            "subscriptionName",
                            e.target.value
                          )
                        }}
                        onBlur={validationType.handleBlur}
                        value={validationType.values.subscriptionName}
                      >
                        <option value="">Select Subscription Name</option>
                        {allShippingTypes?.map(el => {
                          return (
                            <option value={el.name}>
                              {el.name} - {el.title}
                            </option>
                          )
                        })}
                      </select>
                      {perks?.length > 0 && (
                        <div style={{ marginTop: 10 }}>
                          <div className="table-responsive">
                            <Table className="table table-bordered mb-0 funnel-table">
                              <thead>
                                <tr>
                                  <th>Name</th>
                                  <th>Discount Value</th>
                                  <th>Discount Type</th>
                                  <th>Discount Target Selection</th>
                                  <th>Discount Target</th>
                                  <th>Discount Lines</th>
                                </tr>
                              </thead>
                              <tbody>
                                {perks?.map((perk, index) => {
                                  return (
                                    <tr key={index}>
                                      <td>{perk.name}</td>
                                      <td>
                                        {perk?.discount_type === "percentage"
                                          ? `${perk.discount_value}%`
                                          : perk.discount_value}
                                      </td>
                                      <td>{perk.discount_type}</td>
                                      <td>{perk.discount_target_selection}</td>
                                      <td>{perk.discount_target}</td>
                                      <td>{perk.discount_lines}</td>
                                    </tr>
                                  )
                                })}
                              </tbody>
                            </Table>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                  {(validationType.values.subscriptionType ===
                    "standalone_club_shaperbox" ||
                    validationType.values.subscriptionType ===
                      "standalone_shaperbox" ||
                    validationType.values.subscriptionType ===
                      "standalone_club_optional_shaperbox" ||
                    validationType.values.subscriptionType ===
                      "standalone_shaperbox_v1" ||
                    validationType.values.subscriptionType ===
                      "standalone_shaperbox_v2" ||
                    validationType.values.subscriptionType ===
                      "standalone_shaperbox_v3" ||
                    validationType.values.subscriptionType ===
                      "standalone_club_vip_shaperbox") && (
                    <div className="mb-3">
                      <Label className="form-label">
                        {validationType.values.subscriptionType ===
                        "standalone_shaperbox"
                          ? "Required"
                          : "Shaperbox"}{" "}
                        Subscription Name (Subscription Plan)
                      </Label>
                      <select
                        name="subscriptionName2"
                        className="form-select"
                        onChange={e => {
                          handleGetPerks(e.target.value, "subscriptionName2")
                          validationType.setFieldValue(
                            "subscriptionName2",
                            e.target.value
                          )
                        }}
                        onBlur={validationType.handleBlur}
                        value={validationType.values.subscriptionName2}
                      >
                        <option value="">Select Subscription Name</option>
                        {allShippingTypes?.map(el => {
                          return (
                            <option value={el.name}>
                              {el.name} - {el.title}
                            </option>
                          )
                        })}
                      </select>
                      {shaperPerks?.length > 0 && (
                        <div style={{ marginTop: 10 }}>
                          <div className="table-responsive">
                            <Table className="table table-bordered mb-0 funnel-table">
                              <thead>
                                <tr>
                                  <th>Name</th>
                                  <th>Discount Value</th>
                                  <th>Discount Type</th>
                                  <th>Discount Target Selection</th>
                                  <th>Discount Target</th>
                                  <th>Discount Lines</th>
                                </tr>
                              </thead>
                              <tbody>
                                {shaperPerks?.map((perk, index) => {
                                  return (
                                    <tr key={index}>
                                      <td>{perk.name}</td>
                                      <td>
                                        {perk?.discount_type === "percentage"
                                          ? `${perk.discount_value}%`
                                          : perk.discount_value}
                                      </td>
                                      <td>{perk.discount_type}</td>
                                      <td>{perk.discount_target_selection}</td>
                                      <td>{perk.discount_target}</td>
                                      <td>{perk.discount_lines}</td>
                                    </tr>
                                  )
                                })}
                              </tbody>
                            </Table>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                  {(validationType.values.subscriptionType === "autoship" ||
                    validationType.values.subscriptionType ===
                      "autoship_v2") && (
                    <div className="mb-3">
                      <Label className="form-label">
                        Select Autoship Fallback Product
                      </Label>
                      {fallbackProducts?.length > 0 && (
                        <div>
                          {fallbackProducts?.map((product, index) => {
                            return (
                              <div
                                className="selected-product-item"
                                key={index}
                                style={{ justifyContent: "space-between" }}
                              >
                                <div>
                                  <img src={product.images?.[0]?.src} />
                                  <Label className="form-label">
                                    {product.title}
                                  </Label>
                                </div>
                                <i
                                  className="mdi mdi-delete"
                                  style={{ fontSize: 23, cursor: "pointer" }}
                                  onClick={() => {
                                    setFallbackProducts(fallbackProducts_ => {
                                      const updatedProducts = fallbackProducts_
                                        ? fallbackProducts_.filter(
                                            el => el.id !== product.id
                                          )
                                        : []
                                      return updatedProducts
                                    })
                                  }}
                                ></i>
                              </div>
                            )
                          })}
                        </div>
                      )}
                      <div className="offer-product-container">
                        <Button
                          color="primary"
                          type="button"
                          onClick={() => {
                            setIsFallbackOpen(true)
                          }}
                        >
                          Select A Product
                        </Button>
                      </div>
                      <div className="mb-3 mt-3">
                        <Label className="form-label">Autoship Fallback Components Config</Label>
                        <Input
                          name="componentsConfigAutoshipFallback"
                          type="textarea"
                          rows="5"
                          onChange={validationType.handleChange}
                          onBlur={validationType.handleBlur}
                          value={validationType.values.componentsConfigAutoshipFallback}
                        />
                      </div>
                    </div>
                  )}
                  {(validationType.values.subscriptionType === "autoship" ||
                    validationType.values.subscriptionType ===
                      "autoship_v2") && (
                    <>
                      <div className="mb-3">
                        <Label className="form-label">
                          Select Product Type
                        </Label>
                      </div>
                      <div className="d-flex align-items-center mt-2">
                        <div className="form-check form-check-inline">
                          <input
                            type="radio"
                            id="replacementProduct"
                            name="productOption"
                            value="replacement"
                            className="form-check-input"
                            checked={selectedOption === "replacement"}
                            onChange={e => setSelectedOption(e.target.value)}
                            disabled={!!upgradeProduct}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="replacementProduct"
                          >
                            Replacement Product
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            type="radio"
                            id="upgradeProduct"
                            name="productOption"
                            value="upgrade"
                            className="form-check-input"
                            checked={selectedOption === "upgrade"}
                            onChange={e => setSelectedOption(e.target.value)}
                            disabled={!!replacementProducts?.length}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="upgradeProduct"
                          >
                            Upgrade to Autoship
                          </label>
                        </div>
                      </div>
                      {selectedOption === "replacement" &&
                        replacementProducts?.length > 0 && (
                          <div className="mb-3 mt-4">
                            {replacementProducts?.length > 0 && (
                              <div>
                                {replacementProducts?.map((product, index) => {
                                  return (
                                    <div
                                      className="selected-product-item"
                                      key={index}
                                      style={{
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <div>
                                        <img src={product.images?.[0]?.src} />
                                        <Label className="form-label">
                                          {product.title}
                                        </Label>
                                      </div>
                                      <i
                                        className="mdi mdi-delete"
                                        style={{
                                          fontSize: 23,
                                          cursor: "pointer",
                                        }}
                                        onClick={() => {
                                          setReplacementProducts(
                                            replacementProducts => {
                                              return replacementProducts
                                                ? replacementProducts.filter(
                                                    el => el.id !== product.id
                                                  )
                                                : []
                                            }
                                          )
                                        }}
                                      ></i>
                                    </div>
                                  )
                                })}
                              </div>
                            )}
                          </div>
                        )}
                      {selectedOption === "upgrade" && upgradeProduct && (
                        <div className="mb-3 mt-4">
                          <Label className="form-label">Upgrade Product</Label>
                          <div
                            className="selected-product-item"
                            style={{ justifyContent: "space-between" }}
                          >
                            <div>
                              <img
                                src={upgradeProduct.images?.[0]?.src}
                                alt="product"
                              />
                              <Label className="form-label">
                                {upgradeProduct.title}
                              </Label>
                            </div>
                            <i
                              className="mdi mdi-delete"
                              style={{ fontSize: 23, cursor: "pointer" }}
                              onClick={() => setUpgradeProduct(null)}
                            ></i>
                          </div>
                        </div>
                      )}

                      {selectedOption === "replacement" && (
                        <div className="offer-product-container mt-4">
                          <Button
                            color="primary"
                            type="button"
                            data-toggle="modal"
                            data-target="#myModal"
                            onClick={() => {
                              setIsReplacementProducts(true)
                              setIsOpen(true)
                            }}
                          >
                            Select Product
                          </Button>
                        </div>
                      )}

                      {selectedOption === "upgrade" && (
                        <div className="offer-product-container mt-4">
                          <Button
                            color="secondary"
                            type="button"
                            data-toggle="modal"
                            data-target="#myModal"
                            onClick={() => {
                              setIsUpgradedProduct(true)
                              setIsUpgradeModalOpen(true)
                            }}
                          >
                            Select Product
                          </Button>
                        </div>
                      )}
                      {selectedOption === "upgrade" &&
                        upgradeProduct &&
                        upgradeAdditionalProducts?.length > 0 && (
                          <div className="mb-3 mt-4">
                            <Label className="form-label">
                              Add Additional Products to Upgrade
                            </Label>
                            <div>
                              {upgradeAdditionalProducts?.map(
                                (product, index) => {
                                  return (
                                    <div
                                      className="selected-product-item"
                                      key={index}
                                      style={{
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <div>
                                        <img src={product.images?.[0]?.src} />
                                        <Label className="form-label">
                                          {product.title}
                                        </Label>
                                      </div>
                                      <i
                                        className="mdi mdi-delete"
                                        style={{
                                          fontSize: 23,
                                          cursor: "pointer",
                                        }}
                                        onClick={() => {
                                          setUpgradeAdditionalProducts(
                                            upgradeAdditionalProducts => {
                                              return upgradeAdditionalProducts
                                                ? upgradeAdditionalProducts.filter(
                                                    el => el.id !== product.id
                                                  )
                                                : []
                                            }
                                          )
                                        }}
                                      ></i>
                                    </div>
                                  )
                                }
                              )}
                            </div>
                          </div>
                        )}
                      {selectedOption === "upgrade" && upgradeProduct && (
                        <div className="mb-3 mt-4">
                          {upgradeAdditionalProducts?.length === 0 && (
                            <Label className="form-label">
                              Add Additional Products to Upgrade
                            </Label>
                          )}
                          <div className="offer-product-container mt-4">
                            <Button
                              color="primary"
                              type="button"
                              data-toggle="modal"
                              data-target="#myModal"
                              onClick={() => {
                                setIsUpgradeAdditionalProducts(true)
                                setIsModalUpgradeAdditionalProductsOpen(true)
                              }}
                            >
                              Select Product
                            </Button>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                  {selectedOption === "upgrade" && upgradeProduct && (
                    <>
                      <Col lg={12}>
                        <AutoshipExtraFields validationType={validationType} />
                      </Col>
                    </>
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default Subscriptions
